import "./ContactPage.style.css";
import { useEffect } from "react";

const ContactPage = () => {

  useEffect(() => {
    document.title = "Kontakt"
  }, [])

  return (
    <div className="Page">
      <h1>
        Kontaktieren Sie uns
      </h1>
      <img src="anja.jpg" id="image"></img>
      <p id="name">Dr. Anja Linnemann</p>
      Fraunhofer FIT <br></br>
      Schloss Birlinghoven <br></br>
      Konrad-Adenauer-Straße <br></br>
      53757 Sankt Augustin <br></br>
      <p style={{lineHeight: "1px"}}></p> 
      Email: <a href="mailto:anja.linnemann@fit.fraunhofer.de">anja.linnemann@fit.fraunhofer.de</a> <br />
      Telefon: <a href="tel:+49 2241 14-3755">+49 2241 14-3755</a> 
    </div>
  );
};
  
  export default ContactPage;
  