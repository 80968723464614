import { useEffect } from "react";

const AboutUsPage = () => {

  useEffect(() => {
    document.title = "Über uns"
  }, [])

  return (
    <div className="Page">
      <h1>
        Ihr Partner für vernetzte nachhaltige Wertschöpfungs- und Datenökosysteme im Mittelstand
      </h1>
        <b>Zum 01.11.2022 ist das Mittelstand-Digital Zentrum WertNetzWerke gestartet. Hier adressieren wir den deutschen Mittelstand und bringen unsere vielfältigen Erfahrungen aus dem Kompetenzzentrum eStandards ein.</b>
        <p style={{lineHeight: "5px"}} />
        Vor allem die äußeren veränderten Faktoren der letzten Jahre, wie zum Beispiel die Pandemie, der Krieg oder die Energiekrise erhöhen die wirtschaftlichen Unsicherheiten: Lieferketten sind vermehrt gestört, es herrschen Fachkräftemangel und Ressourcenengpässe.
        <p style={{lineHeight: "5px"}} />
        Um diese gegenwärtigen Herausforderungen und denen der Zukunft zu begegnen, stärkt Ihnen das Mittelstand-Digital Zentrum WertNetzWerke als kleines oder mittleres Unternehmen (KMU) das Rückgrat, um in intelligent und digital unterstützten Netzwerken erfolgreich zu agieren.
        <p style={{lineHeight: "5px"}} />
        Dazu werden für Sie vor allem vernetzte nachhaltige Wertschöpfungs- und Datenökosysteme, kooperative Geschäftsprozess- und -modellentwicklung, Kreislaufwirtschaft und Interoperabilität in den Mittelpunkt des Informations- und Umsetzungsangebots gestellt. Zudem sind für den elektronischen Datenaustausch Technologien, wie Gaia-X/International DataSpaces, Plattformökonomie, Künstliche Intelligenz, Internet-of-Things oder Blockchain relevant. Dazu betrachten wir für Sie auch die Rolle von Netzwerkpartnern, Ressourceneffizienz, Kompetenzentwicklung und Change-Management in KMU.
        <p style={{lineHeight: "5px"}} />
        Mit spannenden Best Practices unserer Verbunddigitalisierungsprojekte möchten wir Sie inspirieren, Ihre eigene nachhaltige, digitale Erfolgsgeschichte zu schreiben. Erleben Sie den Nutzen innovativer Technologien in den Wertschöpfungslaboren, die Sie über virtuelle und reale Vernetzung informieren und wo Sie live vor Ort diese in Trainingssimulationen testen können. Eine kollaborative Selbstqualifizierungsumgebung und ein „Metaversum Digitale Wertschöpfungsnetzwerke“ ergänzen für Sie die interaktive Wissenswelt. Erweitern Sie Ihr digitales Know-how bei Unternehmens-Exkursionen, in Schulungen, mit unseren Leitfäden, Checklisten und Tools. Starten Sie einfach und unabhängig Ihr digitales Geschäftsmodell. Wir begleiten Sie gerne.
        <p style={{lineHeight: "5px"}} />
        Unsere Angebote sind für Sie als kleines und mittleres Unternehmen selbstverständlich kostenlos, anbieterneutral und praxisnah. Hierauf können Sie sich verlassen, da wir Teil der Förderinitiative „Mittelstand-Digital“ sind. Diese wird im Rahmen des Förderschwerpunkts „Mittelstand-Digital – Strategien zur digitalen Transformation der Unternehmensprozesse“ vom Bundesministerium für Wirtschaft und Klimaschutz (BMWK) gefördert.
    </div>
  );
};

export default AboutUsPage;
