import { useEffect } from "react";

const PrivacyPage = () => {

  useEffect(() => {
    document.title = "Datenschutz"
  }, [])

  return (
    <div className="Page">
        <h1>Datenschutzinformation</h1>
        <p>
          Im Rahmen der Nutzung dieser Webseite werden personenbezogene Daten
          von Ihnen durch uns als den für die Datenverarbeitung Verantwortlichen
          verarbeitet und für die Dauer gespeichert, die zur Erfüllung der
          festgelegten Zwecke und gesetzlicher Verpflichtungen erforderlich ist.
          Im Folgenden informieren wir Sie darüber, um welche Daten es sich
          dabei handelt, auf welche Weise sie verarbeitet werden und welche
          Rechte Ihnen diesbezüglich zustehen.
        </p>
        <p>
          Personenbezogene Daten sind gemäß Art. 4 Nr. 1
          Datenschutzgrundverordnung (DSGVO) alle Informationen, die sich auf
          eine identifizierte oder identifizierbare natürliche Person beziehen.
        </p>
        <p>
          <a href="#1">1 Geltungsbereich</a>
        </p>
        <p>
          <a href="#2">
            2 Name und Kontaktdaten des für die Verarbeitung Verantwortlichen
            sowie des betrieblichen Datenschutzbeauftragten
          </a>
        </p>
        <p>
          <a href="#3">
            3 Verarbeitung personenbezogener Daten und Zwecke der Verarbeitung
          </a>
        </p>
        <p>
          <a href="#4">4 Weitergabe von Daten</a>
        </p>
        <p>
          <a href="#5">5 Betroffenenrechte</a>
        </p>
        <p>
          <a href="#6">6 Datensicherheit</a>
        </p>
        <p>
          <a href="#7">
            7 Aktualität und Änderung dieser Datenschutzinformation
          </a>
        </p>
        <h4 style={{ paddingTop: "2em" }}>
          <a id="1">1 Geltungsbereich</a>
        </h4>
        <p>
          Diese Datenschutzinformation gilt für Datenverarbeitungen auf allen
          Webseiten des{" "}
          <b>Fraunhofer-Institut für Angewandte Informationstechnik FIT</b>, die
          auf diese Datenschutzinformation Bezug nehmen.
        </p>
        <h4>
          <a id="2">
            2 Name und Kontaktdaten des für die Verarbeitung Verantwortlichen
            sowie des betrieblichen Datenschutzbeauftragten
          </a>
        </h4>
        <p>Verantwortlicher im Sinne von Art. 4 Nr. 7 DSGVO:</p>
        <p>
          Fraunhofer-Gesellschaft zur Förderung der angewandten Forschung e.V.
          <br /> Hansastraße 27 c<br /> 80686 München
        </p>
        <p>
          für ihr
          <br /> Fraunhofer-Institut für Angewandte Informationstechnik&nbsp;FIT
        </p>
        <p>
          Schloss Birlinghoven
          <br /> 53757 Sankt Augustin, Germany
        </p>
        <p>
          (im Folgenden <b>Fraunhofer FIT</b>)
        </p>
        <p>
          E-Mail:&nbsp;
          <a href="mailto:info@fit.fraunhofer.de">info@fit.fraunhofer.de</a>
          <br /> Telefon:&nbsp;+49 2241 14-3333
          <br /> Fax:&nbsp;+49 2241 14-3700
        </p>
        <p>
          Der Datenschutzbeauftragte von Fraunhofer ist unter der o.g. Anschrift
          in München, zu Hd. Datenschutzbeauftragter bzw. unter{" "}
          <a href="mailto:datenschutz@zv.fraunhofer.de">
            datenschutz@zv.fraunhofer.de
          </a>{" "}
          erreichbar. Sie können sich jederzeit bei Fragen zum Datenschutzrecht
          oder Ihren Betroffenenrechten direkt an unseren
          Datenschutzbeauftragten wenden.
        </p>
        <p>
          Alternativ steht Ihnen unser Ansprechpartner Feras Nassaj unter{" "}
          <a href="mailto:feras.nassaj@fit.fraunhofer.de">
            feras.nassaj@fit.fraunhofer.de
          </a>{" "}
          zur Verfügung.
        </p>
        <h4>
          <a id="3">
            3 Verarbeitung personenbezogener Daten und Zwecke der Verarbeitung
          </a>
        </h4>
        <p>
          <b>Beim Besuch der Webseite</b>
        </p>
        <p>
          Sie können unsere Webseite aufrufen, ohne Angaben zu Ihrer Identität
          preisgeben zu müssen. Der auf Ihrem Endgerät eingesetzte Browser
          sendet lediglich automatisch Informationen an den Server unserer
          Webseite (z.B. Browsertyp und -version, Datum und Uhrzeit des
          Zugriffs), um einen Verbindungsaufbau der Webseite zu ermöglichen.
          Hierzu gehört auch die IP-Adresse Ihres anfragenden Endgerätes. Diese
          wird temporär in einem sog. Logfile gespeichert und spätestens nach 30
          Tagen gelöscht.
        </p>
        <p>
          Die Verarbeitung der IP-Adresse erfolgt zu technischen und
          administrativen Zwecken des Verbindungsaufbaus und -stabilität, um die
          Sicherheit und Funktionsfähigkeit unserer Webseite zu gewährleisten
          und etwaige rechtswidrige Angriffe hierauf notfalls verfolgen zu
          können.
        </p>
        <p>
          Die Rechtsgrundlage für die Verarbeitung der IP-Adresse ist Art. 6
          Abs. 1 S. 1 lit. f DSGVO. Unser berechtigtes Interesse folgt aus dem
          erwähnten Sicherheitsinteresse und der Erforderlichkeit einer
          störungsfreien Bereitstellung unserer Webseite.
        </p>
        <p>
          Aus der Verarbeitung der IP-Adresse und anderer Information in dem
          Logfile können wir keine unmittelbaren Rückschlüsse auf Ihre Identität
          ziehen.
        </p>
        <h4>
          <a id="4">4 Weitergabe von Daten</a>
        </h4>
        <p>
          Sofern wir personenbezogene Daten, die wir über die Webseite erheben,
          an Auftragsverarbeiter weitergeben, informieren wir Sie darüber in
          dieser Datenschutzinformation bei dem jeweiligen
          Datenverarbeitungsvorgang unter Nennung des konkreten Empfängers.
        </p>
        <p>
          Im Übrigen geben wir Ihre personenbezogenen Daten nur weiter, wenn
        </p>
        <ul>
          <li>
            Sie gem. Art. 6 Abs. 1 S. 1 lit. a DSGVO Ihre ausdrückliche
            Einwilligung dazu erteilt haben;
          </li>
          <li>
            dies gem. Art. 6 Abs. 1 S. 1 lit. b DSGVO für die Erfüllung eines
            Vertrages mit Ihnen erforderlich ist (zum Beispiel bei Weitergabe an
            Versandunternehmen zum Zwecke der Lieferung der von Ihnen bestellten
            Ware oder bei Weitergabe von Zahlungsdaten an Zahlungsdienstleister
            oder Kreditinstitute, um einen Zahlungsvorgang durchzuführen);
          </li>
          <li>
            für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DSGVO eine
            gesetzliche Verpflichtung besteht.
          </li>
        </ul>
        <p>
          Die weitergegebenen Daten dürfen von den&nbsp;Empfängern
          ausschließlich zu den genannten Zwecken verwendet werden.
        </p>
        <h4>
          <a id="5">5 Betroffenenrechte</a>
        </h4>
        <p>Sie haben das Recht:</p>
        <ul>
          <li>
            <span>
              gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung
              jederzeit gegenüber uns zu widerrufen. Dies hat zur Folge, dass
              wir die Datenverarbeitung, die auf dieser Einwilligung beruhte,
              für die Zukunft nicht mehr fortführen dürfen;
            </span>
          </li>
          <li>
            <span>
              gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten
              personenbezogenen Daten zu verlangen. Insbesondere können Sie
              Auskunft über die Verarbeitungszwecke, die Kategorie der
              personenbezogenen Daten, die Kategorien von Empfängern, gegenüber
              denen Ihre Daten offengelegt wurden oder werden, die geplante
              Speicherdauer, das Bestehen eines Rechts auf Berichtigung,
              Löschung, Einschränkung der Verarbeitung oder Widerspruch, das
              Bestehen eines Beschwerderechts, die Herkunft ihrer Daten, sofern
              diese nicht bei uns erhoben wurden, sowie über das Bestehen einer
              automatisierten Entscheidungsfindung einschließlich Profiling und
              ggf. aussagekräftigen Informationen zu deren Einzelheiten
              verlangen;
            </span>
          </li>
          <li>
            <span>
              gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder
              Vervollständigung Ihrer bei uns gespeicherten personenbezogenen
              Daten zu verlangen;
            </span>
          </li>
          <li>
            <span>
              gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten
              personenbezogenen Daten zu verlangen, soweit nicht die
              Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung
              und Information, zur Erfüllung einer rechtlichen Verpflichtung,
              aus Gründen des öffentlichen Interesses oder zur Geltendmachung,
              Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;
            </span>
          </li>
          <li>
            <span>
              gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen, soweit die Richtigkeit der
              Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig
              ist, Sie aber deren Löschung ablehnen und wir die Daten nicht mehr
              benötigen, Sie jedoch diese zur Geltendmachung, Ausübung oder
              Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß Art. 21
              DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;
            </span>
          </li>
          <li>
            <span>
              gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns
              bereitgestellt haben, in einem strukturierten, gängigen und
              maschinenlesbaren Format zu erhalten oder die Übermittlung an
              einen anderen Verantwortlichen zu verlangen und
            </span>
          </li>
          <li>
            <span>
              gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren.
              In der Regel können Sie sich hierfür an die Aufsichtsbehörde ihres
              üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres
              Unternehmenssitzes wenden.
            </span>
          </li>
        </ul>
        <p>&nbsp;</p>
        <p>
          <b>Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO</b>
        </p>
        <p>
          <b>
            Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen
            Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender
            personenbezogener Daten, die aufgrund von Artikel 6 Absatz 1
            Buchstabe e DSGVO (Datenverarbeitung im öffentlichen Interesse) und
            Artikel 6 Absatz 1 Buchstabe f DSGVO (Datenverarbeitung auf der
            Grundlage einer Interessenabwägung) erfolgt, Widerspruch einzulegen;
            dies gilt auch für ein auf diese Bestimmung gestütztes Profiling von
            Artikel 4 Nr. 4 DSGVO.
          </b>
        </p>{" "}
        <p>
          <b>
            Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten
            nicht mehr verarbeiten, es sei denn, wir können zwingende
            schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre
            Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung
            dient der Geltendmachung, Ausübung oder Verteidigung von
            Rechtsansprüchen.
          </b>
        </p>{" "}
        <p>
          <b>
            Sofern sich Ihr Widerspruch gegen eine Verarbeitung von Daten zum
            Zwecke der Direktwerbung richtet, so werden wir die Verarbeitung
            umgehend einstellen. In diesem Fall ist die Angabe einer besonderen
            Situation nicht erforderlich. Dies gilt auch für das Profiling,
            soweit es mit solcher Direktwerbung in Verbindung steht.
          </b>
        </p>{" "}
        <p>
          <b>
            Möchten Sie von Ihrem Widerspruchsrecht Gebrauch machen, genügt eine
            E-Mail an&nbsp;
            <a href="mailto:datenschutz@zv.fraunhofer.de">
              datenschutzkoordination@zv.fraunhofer.de
            </a>
            .
          </b>
        </p>
        <p>&nbsp;</p>
        <h4>
          <a id="6">6 Datensicherheit</a>
        </h4>
        <p>
          Alle von Ihnen persönlich übermittelten Daten werden mit dem allgemein
          üblichen und sicheren Standard TLS (Transport Layer Security)
          verschlüsselt übertragen. TLS ist ein sicherer und erprobter Standard,
          der z.B. auch beim Onlinebanking Verwendung findet. Sie erkennen eine
          sichere TLS-Verbindung unter anderem an dem angehängten s am http
          (also https://..) in der Adressleiste Ihres Browsers oder am
          Schloss-Symbol im unteren Bereich Ihres Browsers.
        </p>
        <p>
          Wir bedienen uns im Übrigen geeigneter technischer und
          organisatorischer Sicherheitsmaßnahmen, um Ihre Daten gegen zufällige
          oder vorsätzliche Manipulationen, teilweisen oder vollständigen
          Verlust, Zerstörung oder gegen den unbefugten Zugriff Dritter zu
          schützen. Unsere Sicherheitsmaßnahmen werden entsprechend der
          technologischen Entwicklung fortlaufend verbessert.
        </p>
        <h4>
          <a id="7">7 Aktualität und Änderung dieser Datenschutzinformation</a>
        </h4>
        <p>
          Durch die Weiterentwicklung unserer Webseite und Angebote darüber oder
          aufgrund geänderter gesetzlicher bzw. behördlicher Vorgaben kann es
          notwendig werden, diese Datenschutzinformation zu ändern. Die jeweils
          aktuelle Datenschutzinformation kann jederzeit auf der Webseite von
          Ihnen abgerufen und ausgedruckt werden.
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
    </div>
  );
};

export default PrivacyPage;