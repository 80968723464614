const GraphicTile = (props) => {
  let coordinates = props.coords;
  const { setContent } = props.AppStateService;
  const { innerWidth: width } = window; 

  if(width >= 992){
    return (
      <area
        coords={coordinates}
        shape="poly"
        onMouseEnter={() => {
          setContent(props.content);
        }}
        onMouseLeave={() => {
          props.setFixText()
        }}
      />
    );
  } else {
    return (
      <area
        coords={coordinates}
        shape="poly"
        onClick={() => {
          setContent(props.content);
        }}
      />
    );
  }
  
};

export default GraphicTile;
