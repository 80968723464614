const ContentData = {
  LinearMain: {
    text: "Während ihres Lebenszyklus durchlaufen Produkte verschiedene Phasen. In jeder dieser Phasen werden Ressourcen verbraucht, wodurch sogenannte Umweltwirkungen, z. B. Treibhausgasemissionen, entstehen. Viele Produkte durchlaufen ihren Lebenszyklus in kurzer Zeit und werden danach entsorgt, wodurch große Umweltlasten und oftmals auch ein starker Wertverfall entstehen. Die lineare Wertschöpfungskette wird aus diesen Gründen oft mit „take, make, dispose“, also „nehmen, produzieren,entsorgen“, beschrieben.",
    heading: "Denken in Lebenszyklen",
  },
  CycleMain: {
    text: "Die Idee der Kreislaufwirtschaft (Englisch: Circular Economy) orientiert sich an Kreisläufen, die in der Natur zu finden sind. Dort entstehen keine Abfälle, da Nährstoffe verwertet werden und als Basis für neues Leben dienen. Schließt man Kreisläufe, können demnach Ressourcen geschont, schädliche Emissionen und Energieverschwendung reduziert und Abfälle vermieden werden.\nDas aktuell vorherrschende, lineare Wirtschaftssystem Wegwerfwirtschaft wird oft als Gegenteil der Kreislaufwirtschaft bezeichnet. Es führt zu hoher Energie- und Ressourcenverschwendung und letztendlich zu einer Übernutzung endlicher Ressourcen auf der Erde. Die Etablierung einer Kreislaufwirtschaft gilt als notwendiger Schritt auf dem Weg zur Klimaneutralität. Nachhaltigkeits-Expert:innen gehen davon aus, dass bis 2050 die Rohstoffe von drei Erden benötigt werden, um den Markt zu befriedigen, wenn weiter an der Linearwirtschaft festgehalten wird.",
    heading: "Kreislaufwirtschaft",
  },
  Rohstoffe: {
    text: "Zur Herstellung von Produkten werden verschiedene Materialien und Rohstoffe verwendet. Dabei wird zwischen erneuerbaren und nicht erneuerbaren sowie primären und sekundären Rohstoffen unterschieden. Rohstoffe wie Metall und Glas können mit geringen Qualitätsverlusten recycelt und somit als Sekundärrohstoff verwendet werden.",
    heading: "Rohstoffe",
  },
  Design: {
    text: "Das Produktdesign hat einen maßgeblichen Einfluß auf alle vorgelagerten und nachgelagerten Prozesse. In dieser Phase entscheidet sich beispielsweise, ob ein Produkt für eine lange Nutzung oder als Einwegprodukt konzipiert wird. Materialwahl, Verpackung, Produktion, zeitgemäßes oder zeitloses Design und viele weitere Parameter beeinflussen schon in der Designphase den weiteren Lebensweg des Produkts. Ein Produktdesigner hat viele Anforderungen zu erfüllen und viele Parameter zu beachten. Ein Ansatz Produkte und Prozesse nachhaltig zu gestalten ist EcoDesign. [Mehr Dazu]",
    heading: "Design",
  },
  EcoDesign: {
    text: "Eco Design ist ein Gestaltungsansatz für Produkte, mit dem Ziel Umweltbelastungen über den gesamten Produktlebenszyklus zu mindern. Durch verbesserte Produktgestaltung können Produzenten in der Produktplanungs- und -designphase einen positiven Einfluss auf den gesamten Lebensweg des Produktes nehmen. Eco Design ist ein systematischer und umfassender Gestaltungsansatz, der verschiedene Prinzipien und Methodiken bereitstellt, an denen sich Produzenten orientieren können.",
    heading: "Eco Design",
  },
  Produktion: {
    text: "Je nach Funktion und Form des Produktes, eignen sich verschiedene Herstellungsverfahren. Bei der Wahl des geeigneten Herstellungsverfahren, sollten die Ressourcenverbräuche verglichen werden. Additive Herstellungsmethoden, wie der 3D Druck sind durch die geringen Nebenprodukte aus Umweltsicht attraktiv.",
    heading: "Produktion",
  },
  Vertrieb: {
    text: "In linearen Wirtschaftssystemen konzertriert sich der Vertrieb vor allem darauf Waren und Dienstleistungen zum Kunden zu bringen. Dazu wird, je nach Produkt, ein entsprechendes Logistiknetz genutzt oder aufgebaut. In der Kreislaufwirtschaft erweitern sich die Aufgaben des Vertriebs. Der Kunde wird stärker eingebunden, Rücknahmesysteme werden etabliert und neue Services und Vertriebskanäle entstehen, die dem Kunden eine umfassendere User Experience bieten. Moderne, innovative Vertriebswege bieten großes Potential Kunden zu binden und die steigende Nachfrage nach nachhaltigen Produkten und Dienstleistungen zu befriedigen.",
    heading: "Vertrieb",
  },
  Nutzung: {
    text: "In vielen Anwendungen wird der Umwelteinfluss der Nutzungsphase unterschätzt. Für die meisten Produkte gilt jedoch, je länger die Produkte genutzt werden, umso geringer der Materialaufwand und damit der Ressourcenverbrauch. Im Hinblick auf die Kreislaufwirtschaft bedeutet das, dass mit einer Verlängerung der Nutzungsphase die Neuproduktion von Gütern verringert wird aber sich der Servicebedarf erhöht. Somit werden Dienstleistungspotenziale geschaffen, die Raum für innovative Serviceleistungen bieten. Kunden werden gebunden und Ressourcenintensive Industrien minimiert.",
    heading: "Nutzung",
  },
  Nutzungsende: {
    text: "Das Nutzungsende eines Produktes ist dann erreicht, wenn es von niemanden weitergenutzt wird. Das heißt, wenn es weder vom Erstnutzer noch von einem anderen Nutzer weiterverwendet wird. Dies kann beispielsweise bei einem nicht reparierbaren Defekt eines Produktes der Fall sein, bei dem das Produkt nicht mehr den gewünschten Zweck erfüllt. Ein anderer Grund für das Nutzungsende kann die Ablösung des Produktes durch ein moderneres sein, beispielsweise wenn es nicht mehr dem aktuellen Trend entspricht. Durch die Gestaltung von ästhetisch zeitlosen oder modularen Produkten kann dieser Grund für das Nutzungsende hinausgezögert werden.",
    heading: "Nutzungsende",
  },
  Entsorgung: {
    text: "Nach dem Nutzungsende eines Produktes, wird dieses, sofern nicht einzelne Komponenten oder Materialien wiederverwendet oder recycelt werden, entsorgt. In Bezug auf die Abfallwirtschaft ist das Ziel der Kreislaufwirtschaft die stoffliche Verwertung nicht vermeidbarer Abfälle. Weiterhin sollen Innovation dazu beitragen die Abfallwirtschaft zu einer Quelle für die Beschaffung von Rohstoffen für die Produktion von Gütern weiterzuentwickeln. Nicht verwertbare Abfälle müssen bevor sie in Deponien abgelagert oder thermisch verwertet werden, aufwendig mechanisch, biologisch oder thermisch behandelt werden, wodurch weitere Umweltwirkungen und Kosten entstehen.",
    heading: "Entsorgung",
  },
  Wiederverwenden: {
    text: "Durch die Wiederverwendung von Produkten wird deren Nutzungsphase verlängert und langfristige weniger Produkte hergestellt. Werden gebrauchte Produkte weiterverkauft oder verschenkt und von weiteren Nutzer:innen verwendet, können Neukäufe vermieden werden.",
    heading: "Wiederverwenden",
  },
  Reparieren: {
    text: "Die Reparatur von Produkten führt dazu, dass die Nutzungsphase verlängert und weniger ressourcenintensive Neuprodukte produziert werden. Durch die Reparatur von Produkten können zudem neue, innovative Serviceangebote entstehen. Die Reparaturfähigkeit eines Produkts muss schon im Designprozess berücksichtigt werden, z. B. durch den Entwurf modular aufgebauter Produkte, bei denen sich einzelne Bauteile leicht austauschen lassen.",
    heading: "Reparieren",
  },
  Reduzieren: {
    text: "Beim Reduzieren geht es auf der einen Seite um die effiziente Nutzung von Materialien, ohne dabei die Nutzungsanforderungen einzuschränken. Auf der anderen Seite bezieht sich das Reduktionsprinzip aber auch auf die effiziente Nutzung von Ressourcen und Rohstoffen über den gesamten Lebenszyklus eines Produktes. Reduzieren bedeutet also auch, dass für ein Produkt unter Umständen zunächst mehr Material eingesetzt wird, das Produkt aber so gestaltet ist, dass es langfristig genutzt wird – was auf lange Sicht den Materialeinsatz verringert.",
    heading: "Reduzieren",
  },
  Wiederaufbereiten: {
    text: "Die Wiederaufbereitung von Produkten oder Produktkomponenten führt dazu, dass weniger Ressourcen für die Herstellung neuer Produkte verbraucht werden. So können Komponenten eines ausrangierten Produktes in die Produktion neuer Ware einfließen oder als Ersatzteile für defekte Produkte dienen. Auch das Upcycling, also die gezielte Aufwertung von Produkten und Materialien nach Nutzungsende, führt zu einem geringeren Ressourcenverbrauch.",
    heading: "Wiederaufbereiten",
  },
  Recycling: {
    text: "Recycling ist eine der bekanntesten Vorgehensweisen, um Produktkreisläufe zu schließen und Materialien effizient wiederzuverwenden. Es beschreibt die Zurückgewinnung von Rohmaterial aus aussortierten oder defekten Produkten, das daraufhin als Eingangsmaterial zur Produktion neuer Produkte genutzt werden. Dadurch entfällt ein Großteil der Umweltwirkungen, die beim Ressourcenabbau anfallen würden. Über die Materialwahl und entsprechende Produktionsverfahren lässt sich stark beeinflussen, wie gut ein Produkt recycelt werden kann.",
    heading: "Recycling",
  },
};

export default ContentData;
