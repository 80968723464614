import Tile from "../GraphicTile/GraphicTile.js";
import Data from "../../Assets/Content/ContentData.js";
import imageMapResize from "image-map-resizer";
import { useEffect } from "react";

const CycleEconomyMap = (props) => {
  const { setContent, economyType, setFixText } = props.AppStateService;

  useEffect(() => {
    imageMapResize();
  }, [])

  return (
    <map
      name="image-map-full"
    > 
      <Tile AppStateService={{ setContent, economyType, setFixText }}
        coords="170,153,273,56,380,159,385,231,465,309,540,317,568,334,562,370,484,385,434,373,361,317,275,266"
        content={Data.Rohstoffe}
      />
      <Tile AppStateService={{ setContent, economyType, setFixText }}
        coords="554,312,605,279,577,209,639,171,663,250,671,261,767,262,790,295,761,313,713,313,636,340,573,362,580,328"
        content={Data.EcoDesign}
      />
      <Tile AppStateService={{ setContent, economyType, setFixText }}
        coords="786,262,863,284,902,223,978,180,1021,201,1001,268,974,327,961,359,987,408,974,446,941,443,879,359,778,321,807,296"
        content={Data.Produktion}
      />
      <Tile AppStateService={{ setContent, economyType, setFixText }}
        coords="786,262,863,284,902,223,978,180,1021,201,1001,268,974,327,961,359,987,408,974,446,941,443,879,359,778,321,807,296"
        content={Data.Produktion}
      />
      <Tile AppStateService={{ setContent, economyType, setFixText }}
        coords="949,456,979,459,994,428,1012,460,1086,449,1125,526,1087,589,1050,603,1021,596,1012,629,978,641,960,611"
        content={Data.Vertrieb}
      />
      <Tile AppStateService={{ setContent, economyType, setFixText }}
        coords="954,625,965,651,1001,648,987,691,1024,740,1007,802,950,812,906,778,867,802,839,786,846,749,907,701"
        content={Data.Nutzung}
      />
      <Tile AppStateService={{ setContent, economyType, setFixText }}
        coords="851,807,761,838,720,838,715,924,640,917,593,883,600,802,586,794,566,763,593,738,706,776,829,761,829,789"
        content={Data.Nutzungsende}
      />
      <Tile AppStateService={{ setContent, economyType, setFixText }}
        coords="570,785,553,753,581,733,507,716,439,721,385,757,359,780,341,807,277,826,256,856,266,909,310,921,348,908,377,895,363,848,368,828,406,819,491,776"
        content={Data.Entsorgung}
      />
      <Tile AppStateService={{ setContent, economyType, setFixText }}
        coords="820,748,817,691,831,654,855,624,883,603,922,593,944,570,927,554,893,555,862,566,836,581,815,604,795,630,778,658,772,688,773,721,776,756"
        content={Data.Wiederverwenden}
      />
      <Tile AppStateService={{ setContent, economyType, setFixText }}
        coords="999,681,1027,687,1053,730,1055,781,1037,821,1014,842,972,854,941,852,905,840,889,823,872,810,904,783,914,795,931,802,967,810,987,805,1002,793,1014,768,1012,740,1006,726,995,716"
        content={Data.Reparieren}
      />
      <Tile AppStateService={{ setContent, economyType, setFixText }}
        coords="498,302,510,200,547,152,593,128,644,122,680,128,715,150,739,183,754,219,761,252,728,248,724,230,707,194,676,171,653,162,611,161,593,168,571,182,546,221,542,249,539,285,518,306"
        content={Data.Reduzieren}
      />
      <Tile AppStateService={{ setContent, economyType, setFixText }}
        coords="760,762,715,766,701,671,708,608,724,541,765,471,818,417,854,389,888,394,877,427,833,459,792,510,769,556,755,605,745,658"
        content={Data.Wiederaufbereiten}
      />
      <Tile AppStateService={{ setContent, economyType, setFixText }}
        coords="581,727,544,688,517,641,502,594,497,549,502,516,519,450,528,434,518,401,477,410,460,442,448,482,442,535,438,571,444,615,465,665,478,692,494,713"
        content={Data.Recycling}
      />
    </map>
  );
};

export default CycleEconomyMap;
