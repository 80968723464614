const BuildData = {
  BuidlType: "Mittelstand",
  NavbarContent: {
    NavbarBrandLink: "https://www.estandards-mittelstand.de/",
    NavbarLogo: "logo-kompetenzzentrum.png",
    NavbarLogoAlt: "Logo Mittelstand 4.0 Kompetenzzentrum eStandards",
    NavbarBrandID: "Mittelstand-Logo",
  },
};

export default BuildData;
