import grafic from "../../Assets/Images/Ökobilanz_Ecodesign.png";
import "./EcoDesignPage.style.css";

import EcoDesignMap from "../../Components/EcoDesignMap/EcoDesignMap";
import Data from "../../Assets/Content/EcoDesignData";
import { useEffect } from "react";

const EcoDesignPage = (props) => {
  const { content, setContent } = props.AppStateService;

  useEffect(() => {
    document.title = "Kreislaufwirtschafts-Guide | EcoDesign";
    setFixText();
  }, []);

  let setFixText = () => {
    setContent(Data.Main);
  };

  return (
    <div>
      <div className="hover-text">
        Fahren sie mit der Maus über die Elemente der Grafik, um mehr zu
        erfahren.
      </div>
      <div id="eco-map-container">
        <img
          src={grafic}
          alt=""
          useMap="#image-map-full"
          style={{
            maxWidth: "100%",
            height: "auto",
          }}
          onClick={() => {
            setContent(Data.Main);
          }}
        />
        <EcoDesignMap
          AppStateService={{
            setContent,
            setFixText,
          }}
        />
      </div>
      <div className="eco-text-container">
        <div className="heading">{content?.heading}</div>
        <p
          dangerouslySetInnerHTML={{
            __html: content?.text.replace(/\n/g, "<br />"),
          }}
        />
      </div>
    </div>
  );
};

export default EcoDesignPage;
