import "./PartnersPage.style.css";
import React, { useEffect } from "react";
import handshake from "../../Assets/Images/Ökobilanz_Partnerschaften.png";

const PartnersPage = () => {

  useEffect(() => {
    document.title = "Kreislaufwirtschafts-Guide | Partnerschaften"
  }, [])

  return (
    <div> 
        <div className="text-container-partners">
            <div className="heading">Partnerschaften</div>
            Häufig ist es für Unternehmen mit hohen Aufwänden verbunden, ihr Geschäftsmodell in Richtung Kreislaufwirtschaft umzugestalten. Partnerschaften helfen dabei, diese Aufwände zu reduzieren und den eigenen Produktlebenszyklus zirkulärer zu gestalten.
            Partnerunternehmen können zum Beispiel das Recycling von Produktionsabfällen und ausrangierten Produkten übernehmen oder aber bei der Entwicklung neuer Materialien oder Produkte unterstützen.
            Durch die Bildung von Partnerschaften teilen Unternehmen Kompetenzen, Maschinen oder Plattformen miteinander und profitieren voneinander - meistens ohne (hohe) Investitionskosten.
        </div>
        <div className="image-container-partners">
        <img
            src={handshake}
            alt=""
            useMap="#image-map-full"
            className="partner-image"
          />
        </div>
    </div>
  );
};
  
export default PartnersPage;
  