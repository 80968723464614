import IntroPage from "../IntroPage/IntroPage";
import PartnersPage from "../PartnersPage/PartnersPage";
import EcoDesignPage from "../EcoDesignPage/EcoDesignPage";
import ViewNavigator from "../../Components/ViewNavigator/ViewNavigator";
import React from "react";
import "./MainPage.style.css";
import { useState } from "react";
import StrategyPage from "../StrategyPage/StrategyPage";
import Data from "../../Assets/Content/ContentData.js";

const MainPage = (props) => {
  const { content, setContent, economyType, setEconomyType } =
    props.AppStateService;
  const [view, setView] = useState("Intro");
  const [circularEconomyState, setCircularEconomyState] = useState(true);

  let onChangeView = (e) => {
    setView(e);
  };

  let onChangeEconomySwitch = (e) => {
    let eS = circularEconomyState;
    eS = !eS;
    console.log("Circular Economy", eS);
    setCircularEconomyState(eS);
    let val = "linear";
    if (eS === true) {
      val = "circular";
    }
    setEconomyType(val);
    if (val === "linear") {
      setContent(Data.LinearMain);
      setEconomyType(val);
    } else {
      setContent(Data.CycleMain);
      setEconomyType(val);
    }
  };

  let getView = () => {
    if (view === "Intro") {
      return (
        <IntroPage
          AppStateService={{ content, setContent, economyType, setEconomyType }}
        />
      );
    } else if (view === "EcoDesign") {
      return <EcoDesignPage AppStateService={{ content, setContent }} />;
    } else if (view === "Strategies") {
      return (
        <StrategyPage
          AppStateService={{ content, setContent, economyType, setEconomyType }}
        />
      );
    } else if (view === "Partners") {
      return <PartnersPage />;
    }
  };

  return (
    <div className="Page main-page">
      <ViewNavigator
        onChangeView={onChangeView}
        onChangeEconomySwitch={onChangeEconomySwitch}
        currentView={view}
        eceo
      />
      {getView()}
    </div>
  );
};

export default MainPage;
